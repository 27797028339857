import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import styled from "styled-components";
import {
  mainRed,
  mainWhite,
  innerWidth,
  lightGrey,
  screen,
} from "../components/common/variables";
import IconSwirl from "../images/svg/icon-swirl.svg";
import SEO from "../components/seo";
import SubBanner from "../components/banner/sub";
import Quote from "../components/quote";
import Cta from "../components/cta";

const Wrapper = styled.div`
  .banner {
    position: relative;
    background: ${lightGrey};
    border-top: 22px ${mainWhite} solid;
    @media ${screen.xsmall} {
      border-top: 15px ${mainWhite} solid;
      border-bottom: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-top: 17px ${mainWhite} solid;
      border-bottom: 17px ${mainWhite} solid;
    }

    .inner-wrapper {
      .featured-txt {
        text-align: center;
        @media ${screen.small} {
          text-align: left;
        }

        .inner-txt {
          .capital-heading {
            margin: 12px 0 0 0;
            @media ${screen.xsmall} {
              margin: 25px 0 0 0;
            }
          }
        }
      }
    }

    .bg {
      order: -1;
      @media ${screen.small} {
        order: 0;
      }
    }
  }

  .why-join {
    position: relative;
    z-index: 2;
    @media ${screen.xsmall} {
      border-bottom: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-bottom: 17px ${mainWhite} solid;
    }

    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 0 50px 0;
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        flex-direction: row;
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }

      .col {
        @media ${screen.small} {
          width: 50%;
        }

        .capital-subheading {
          margin: 0 0 22px 0;
          text-align: center;
          @media ${screen.xsmall} {
            margin: 0 0 30px 0;
          }
          @media ${screen.small} {
            text-align: left;
            max-width: 360px;
          }
          @media ${screen.medium} {
            max-width: none;
          }
          @media ${screen.large} {
            margin: 0 0 35px 0;
          }
        }

        &--right {
          @media ${screen.small} {
            margin: 0 0 0 10px;
          }

          .bordered-p {
            border-bottom: 2px ${mainRed} solid;
            margin: 0 0 25px 0;
            padding: 0 0 25px 0;
            text-align: center;
            @media ${screen.xsmall} {
              padding: 0 0 50px 0;
              margin: 0 0 50px 0;
              text-align: left;
            }

            p {
              font-size: 1.22rem;
              font-weight: 400;
              @media ${screen.xsmall} {
                font-size: 1.4rem;
              }
              @media ${screen.large} {
                font-size: 1.5rem;
              }
            }

            strong {
              font-weight: 600;
            }

            &:last-child {
              border-bottom: none;
              padding: 0;
              margin: 0;
            }
          }
        }

        .btn {
          display: none;
          @media ${screen.small} {
            display: block;
          }

          &--mobile {
            display: block;
            text-align: center;
            margin: 30px 0 0 0;
            @media ${screen.small} {
              display: none;
            }
          }
        }
      }
    }

    .icon-decor {
      position: absolute;
      display: block;
      top: -52px;
      right: -38px;
      @media ${screen.small} {
        top: auto;
        right: auto;
        bottom: -96px;
        left: -35px;
      }

      svg {
        width: 85px;
        transform: scale(-1) rotate(-18deg);
        @media ${screen.xsmall} {
          transform: scale(1) rotate(0);
        }
        @media ${screen.small} {
          width: 138px;
        }
      }
    }
  }

  .quote {
    border-bottom: 22px ${mainWhite} solid;
    @media ${screen.xsmall} {
      border-bottom: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-bottom: 17px ${mainWhite} solid;
    }
  }
`;

const JoinTheChoir = ({ data }) => {
  const {
    top_description,
    bottom_description,
    why_join_heading,
    quote_message,
    quote_author,
    banner_heading,
    banner_image,
    meta_description,
    title_tag,
  } = data.content.data;

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <SubBanner className="banner" bgUrl={banner_image.url}>
          <div className="inner-wrapper">
            <div className="featured-txt">
              <div className="inner-txt">
                <p className="red-heading">GET INVOLVED</p>
                <h1 className="capital-heading">{banner_heading.text}</h1>
              </div>
            </div>
          </div>
        </SubBanner>

        <section className="why-join">
          <div className="inner-wrapper">
            <div className="col">
              <h3 className="capital-subheading">{why_join_heading.text}</h3>
            </div>

            <div className="col col--right">
              <div>
                <div
                  className="bordered-p"
                  dangerouslySetInnerHTML={{ __html: top_description.html }}
                />
                <div
                  className="bordered-p"
                  dangerouslySetInnerHTML={{
                    __html: bottom_description.html,
                  }}
                />
              </div>
            </div>
          </div>
          <span className="icon-decor">
            <IconSwirl />
          </span>
        </section>

        <Quote
          className="quote"
          message={quote_message}
          author={quote_author}
        />

        <Cta heading={"Get in touch to join"} />
      </Wrapper>
    </Layout>
  );
};

export default JoinTheChoir;

export const dataQuery = graphql`
  {
    content: prismicJoinPage {
      data {
        banner_heading {
          text
        }
        banner_image {
          alt
          url
        }
        why_join_heading {
          text
        }
        top_description {
          html
        }
        bottom_description {
          html
        }
        quote_message
        quote_author
        title_tag
        meta_description
      }
    }
  }
`;
